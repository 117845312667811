import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const AlertServie = {
  init() {
    Vue.use(VueSweetalert2);
  },
  successMessage() {
    Vue.swal.fire({
      icon: "success",
      type: "success",
      title: "success",
      showConfirmButton: false,
      timer: 1500,
    });
  },
  errorMessage(title) {
    Vue.swal.fire({
      icon: "error",
      type: "error",
      title: title,
      showConfirmButton: false,
      timer: 1500,
    });
  },
  confirm() {
    return new Promise((resolve) => {
      Vue.swal
        .fire({
          title: "Are you sure?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "cancel",
          confirmButtonText: "yes",
        })
        .then((result) => {
          resolve(result.value);
        });
    });
  },
};

export default AlertServie;
