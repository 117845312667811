import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";


// action types
export const LOGIN = "login";
export const LOGOUT = "logout";
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("/auth/login", credentials)
        .then((response) => {
          response.data.user.token = response.data.access_token;
          context.commit(SET_AUTH, response.data.user);
          resolve(response.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.error);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
 
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    if (user.token != "") JwtService.saveToken(user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
