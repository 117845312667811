import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      name: "",
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          path: "/",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          path: "exchanges",
          name: "categories",
          component: () => import("@/view/pages/exchange/exchanges.vue"),
          children: [
            {
              path: "/",
              component: () => import("@/view/pages/exchange/list.vue"),
            },
           
          ],
        },
      ],
    },
   

    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
